import { CardSlot, StripeScorrevoleCard } from './stripeScorrevoleCard/StripeScorrevoleCard';

import Marquee from 'react-fast-marquee';
import { StripeScorrevoleContent } from './stripeScorrevoleContent';
import { Wrapper } from '../wrapper/Wrapper';
import { convertInCurrencyValue } from 'utility/functions';
import styles from './StripeScorrevole.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';

export type StripeScorrevoleProps = {
  slotTotali?: number;
  slotPromo?: number;
  maxJackpot?: {
    amount: number;
    cardSlot?: CardSlot;
  };
  totJackpot?: number;
};

export const StripeScorrevole: React.FC<StripeScorrevoleProps> = ({ ...props }: StripeScorrevoleProps) => {
  const labelMaxJackpot = useGetLabelByKey('max-jackpot')!;
  const labelSlotTotali = useGetLabelByKey('slot-totali')!;
  const labelSlotPromo = useGetLabelByKey('slot-in-promo')!;
  const labelJackpotGlob = useGetLabelByKey('jackpot-globale')!;
  return (
    <Wrapper noPaddingRight={true} noPaddingLeft={true}>
      <Marquee play gradient={false} direction="left" speed={105} loop={0} className={styles.marquee}>
        {String(props.slotTotali) !== '0' && (
          <StripeScorrevoleContent
            label={labelSlotTotali}
            classNameStripe={styles.stripe}
            classNameTitle={styles.stripeTitle}
            classNameContent={styles.stripeContent}
            value={String(props.slotTotali)}
          />
        )}
        {String(props.maxJackpot) !== '0' && (
          <StripeScorrevoleContent
            label={labelMaxJackpot}
            classNameStripe={styles.stripe}
            classNameTitle={styles.stripeTitle}
            classNameContent={styles.stripeContent}
            value={convertInCurrencyValue(props.maxJackpot!.amount / 100)}
          >
            {props.maxJackpot!.cardSlot?.image && (
              <StripeScorrevoleCard
                classNameMiniCard={styles.miniCard}
                classNameImage={styles.image}
                classNameSideContent={styles.sideContent}
                classNameImgTitle={styles.imgTitle}
                classNameImgSubTitle={styles.imgSubTitle}
                cardSlot={props.maxJackpot!.cardSlot}
              />
            )}
          </StripeScorrevoleContent>
        )}

        {props.totJackpot && (
          <StripeScorrevoleContent
            label={labelJackpotGlob}
            classNameStripe={styles.stripe}
            classNameTitle={styles.stripeTitle}
            classNameContent={styles.stripeContent}
            value={String('€' + ' ' + convertInCurrencyValue(props.totJackpot / 100))}
          />
        )}
        {String(props.slotPromo) !== '0' && (
          <StripeScorrevoleContent
            label={labelSlotPromo}
            classNameStripe={styles.stripe}
            classNameTitle={styles.stripeTitle}
            classNameContent={styles.stripeContent}
            value={String(props.slotPromo)}
          />
        )}
      </Marquee>
    </Wrapper>
  );
};
