import { GamesSkillGameDto, GamesStripe } from 'types/swagger';
import {
  convertInBannerType,
  convertInPokerTournaments,
  convertInPokerTournamentsAvailable,
  updateVisualComposer,
} from 'lib/datoCms/utils';
import { createNewStripe, matchCustomSlotFromGame } from 'components/page-content/slot-top/utils/utils';

import { Banner } from 'components/banner';
import { BingoGame } from 'lib/api/bingoCatalogResponseApi';
import { DatoCmsVisualComposer } from 'lib/datoCms/types';
import { GameQuickSlotResponseApi } from 'lib/api/quickSlotResponseApi';
import { PokerTournamentResponseApi } from 'lib/api/pokerCatalogResponseApi';
import { Table } from 'types/casino-live';
import { VisualComposer } from '../VisualComposer';
import { handleConsoleGameInVisualComposer } from 'utility/functions';

interface CatalogComposition<K> {
  games: Array<Table | BingoGame | GamesSkillGameDto | PokerTournamentResponseApi>; //Array<K & { id: string | number | null; slug: string }>;
  listQuickSlot?: Array<GameQuickSlotResponseApi>;
  stripe?: GamesStripe;
  pageBannerList?: Array<Banner>;
}

interface PageCompositionPropsType<K> {
  visualComposer?: Array<DatoCmsVisualComposer>;
  catalog: CatalogComposition<K>;
  slug: string;
}

// TODO define return type for function
export function createPageComposition<K>({
  visualComposer,
  catalog,
  slug,
}: PageCompositionPropsType<K>): Array<VisualComposer<any> | undefined> {
  let bannerPromoCounter = 0;
  return (visualComposer ?? [])
    .map((composition) => {
      const { banners, _modelApiKey } = composition;
      const list: {
        [key: string]: (composition?: DatoCmsVisualComposer) => VisualComposer<any> | undefined;
      } = {
        banner_on_page: () => {
          return banners ? updateVisualComposer(composition, convertInBannerType(banners[0])) : undefined;
        },
        quick_slot: () => {
          if (catalog?.listQuickSlot?.length && composition.quickSlot) {
            return {
              ...updateVisualComposer(composition, catalog.listQuickSlot),
            };
          }
          return undefined;
        },
        console_giochi_casino_live_list: () => {
          const gameSlots = getGamesByType<any, any>(catalog, composition?.typeOfSlot);
          if (gameSlots?.length) {
            return handleConsoleGameInVisualComposer(composition, catalog!, gameSlots, 'casino/live');
          }
          return undefined;
        },
        console_giochi_casino_list: () => {
          const gameSlots = getGamesByType<any, any>(catalog, composition?.typeOfSlot);
          if (gameSlots && gameSlots.length > 0) {
            return handleConsoleGameInVisualComposer(composition, catalog, gameSlots, slug);
          }
          return undefined;
        },
        custom_casino_list: () => {
          if (composition.casino && catalog.games) {
            return {
              ...updateVisualComposer(
                composition,
                matchCustomSlotFromGame(composition.casino, catalog.games as Array<GamesSkillGameDto>),
                slug
              ),
            };
          }
          return undefined;
        },
        console_giochi_slot_list: () => {
          const gameSlots = getGamesByType<any, any>(catalog, composition?.typeOfSlot);
          if (gameSlots && gameSlots?.length > 0) {
            return handleConsoleGameInVisualComposer(composition, catalog, gameSlots, slug);
          }
          return undefined;
        },
        custom_slot_list: () => {
          if (composition.slot && catalog.games) {
            return {
              ...updateVisualComposer(
                composition,
                matchCustomSlotFromGame(composition.slot, catalog.games as Array<GamesSkillGameDto>),
                slug
              ),
            };
          }
          return undefined;
        },
        stripe_scorrevole: () => {
          if (catalog.stripe && catalog.games) {
            const newStripe = createNewStripe(catalog.stripe, catalog.games as Array<GamesSkillGameDto>);
            return {
              ...updateVisualComposer(composition, newStripe),
            };
          }
          return undefined;
        },
        console_giochi_card_games_list: () => {
          const gameSlots = getGamesByType<any, any>(catalog, composition?.typeOfSlot);
          if (gameSlots && gameSlots.length > 0) {
            return handleConsoleGameInVisualComposer(composition, catalog, gameSlots, slug);
          }
          return undefined;
        },
        custom_card_games_list: () => {
          if (!!composition.cardGames?.length && catalog.games) {
            return {
              ...updateVisualComposer(
                composition,
                matchCustomSlotFromGame(composition.cardGames, catalog.games as Array<GamesSkillGameDto>),
                slug
              ),
            };
          }
          return undefined;
        },
        console_giochi_bingo_list: () => {
          const gamesBingo = catalog[composition?.typeOfSlot! as keyof typeof catalog] as BingoGame[];

          if (gamesBingo && gamesBingo.length > 0) {
            return handleConsoleGameInVisualComposer(composition, catalog, gamesBingo, slug);
          }
          return undefined;
        },
        promo: () => {
          if (catalog.pageBannerList) {
            const banner = [catalog.pageBannerList[`${bannerPromoCounter}`]];
            bannerPromoCounter++;
            if (banner[0]) {
              return {
                ...updateVisualComposer(composition, banner),
              };
            }
          }
          return undefined;
        },
        custom_poker_list: () => {
          if (composition.pokerTournamentsMainList) {
            return {
              ...updateVisualComposer(composition, convertInPokerTournaments(composition.pokerTournamentsMainList)),
            };
          }
          return undefined;
        },
        poker_tournaments_available: () => {
          if (composition.pokerTournamentsAvailable) {
            return {
              ...updateVisualComposer(
                composition,
                convertInPokerTournamentsAvailable(composition.pokerTournamentsAvailable)
              ),
            };
          }
          return undefined;
        },
      };
      if (Object.keys(list).includes(_modelApiKey)) {
        return list[_modelApiKey](composition);
      }
      // TODO aggiungere trace su AppInsight
      return null;
    })
    .filter((x) => !!x) as Array<VisualComposer<{}>>;
}

export function getGamesByType<T, K extends { games: Array<T & { id: string | number | null }> }>(
  catalog: K,
  key: string | undefined
): Array<T> | undefined {
  const { games, ...listOfSlots } = catalog;
  //     // CAST TO UNKNOWN IN ORDER TO OVERLAP TYPE CHECK
  //     //  DOUBLE == INSTEAD === IN ORDER TO SATISFY NUMBER OR STRING CHECK
  const x = (catalog[key as keyof typeof listOfSlots] as unknown as Array<string>)
    ?.map((slotId) => {
      return games.find((game) => game.id == slotId);
    })
    .filter((game) => game !== undefined);
  return x as T[];
}
