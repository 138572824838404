import { SuggestionCard, SuggestionCardProps } from '../../cards/suggestionCard/SuggestionCard';

import { SectionTitle } from '../../sectionTitle';
import { Wrapper } from '../../wrapper';
import { breakpoint } from '../../../utility/constant';
import styles from './SuggestionCarousel.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useTypedSelector } from 'lib/centralStore';
import { enComparer } from 'hooks';
import useWindowSize from 'hooks/useWindowSize';

export type SuggestionCarouselProps = {
  listItem: SuggestionCardProps[];
};

export const SuggestionCarousel: React.FC<SuggestionCarouselProps> = ({ listItem }: SuggestionCarouselProps) => {
  const labelPotrebbePiacerti = useGetLabelByKey('potrebbe-piacerti')!;
  const labelScopri = useGetLabelByKey('scopri-potrebbe-piacerti')!;
  const { doCheck } = useWindowSize();

  const [isSM] = doCheck([{ comparer: enComparer.equal | enComparer.greater, size: breakpoint.sm }]);

  return (
    <Wrapper bgColorTransparent noPaddingRight noPaddingLeft>
      <div className={styles.container}>
        <SectionTitle title={labelPotrebbePiacerti} noPadding={isSM} className={styles.title} />
        <div className={styles.cardsContainer}>
          {listItem &&
            listItem.map((item) => {
              const { id, image, link, title } = item;
              return (
                <SuggestionCard
                  key={id}
                  image={image}
                  label={labelScopri}
                  link={link}
                  title={title}
                  className={styles.card}
                />
              );
            })}
        </div>
      </div>
    </Wrapper>
  );
};
