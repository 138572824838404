import { DynamicImage } from 'components/Image';
import { ImageProps } from 'next/image';
import { breakpoint } from '../../../utility/constant';
import { isNotEmpty } from 'utility/functions';
import { useMemo } from 'react';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type StripeScorrevoleCardProps = {
  classNameMiniCard: string;
  classNameImage: string;
  classNameSideContent: string;
  classNameImgTitle: string;
  classNameImgSubTitle: string;
  cardSlot: CardSlot;
  isCover?: boolean;
};
export type CardSlot = {
  title?: string;
  image: ImageProps & { srcThumbnail: string; srcCover: string };
  tema?: string[];
};

export const StripeScorrevoleCard: React.FC<StripeScorrevoleCardProps> = ({ ...props }: StripeScorrevoleCardProps) => {
  const { doCheck } = useWindowSize();
  const [isLarge] = doCheck([{ comparer: enComparer.greater | enComparer.equal, size: breakpoint.lg }]);

  const { srcImage, hasImg } = useMemo(() => {
    const srcImage = props.isCover ? props.cardSlot.image?.srcCover : props.cardSlot.image?.srcThumbnail;
    const hasImg = isNotEmpty(srcImage);

    return {
      srcImage,
      hasImg,
    };
  }, [props.cardSlot.image?.srcCover, props.cardSlot.image?.srcThumbnail, props.isCover]);

  return (
    <div className={props.classNameMiniCard}>
      {hasImg && (
        <DynamicImage
          src={`${srcImage}`}
          alt={props.cardSlot.image?.alt ?? ''}
          title={props.cardSlot.image?.alt}
          width={isLarge ? '77' : '38'}
          height={isLarge ? '88' : '48'}
          loading="lazy"
          decoding="async"
          className={props.classNameImage}
        />
      )}
      <div className={props.classNameSideContent}>
        {props.cardSlot.tema && <span className={props.classNameImgTitle}>{[...props.cardSlot.tema].join(', ')}</span>}
        {props.cardSlot?.title && <p className={props.classNameImgSubTitle}>{props.cardSlot.title}</p>}
      </div>
    </div>
  );
};
