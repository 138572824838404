import { Icon, IconsEnum } from '../../../Icons';
import React, { useMemo } from 'react';

import { AnchorLink } from 'components/anchorLink';
import { CardType } from 'components/carousels/components/conditionalCard/ConditionalCard';
import styles from './CardListEmptyResult.module.scss';
import { useGetLabelsByKeys } from 'hooks/useLingUI';
import { useRouter } from 'next/router';

export type CardListEmptyResultProps = {
  type: CardType | string;
  skipLink?: boolean;
};

export const CardListEmptyResult = ({ type, skipLink }: CardListEmptyResultProps) => {
  const router = useRouter();
  const pageTopUrl = useMemo(() => router.pathname.replace('/[slug]', ''), [router]);

  const [notFoundLabel, foundNewGamesLabel] = useGetLabelsByKeys(['games-not-found', 'found-new-game']);
  const cardName = () => {
    switch (type) {
      case 'casinolive':
      case 'casino':
        return 'casino';
      case 'bingo':
        return 'bingo';
      case 'giochidicarte':
      case 'lotteries':
        return 'giochi';
      default:
        return 'slot';
    }
  };
  const txt = `${notFoundLabel}`.replace('{{CARD-TYPE}}', cardName());
  return (
    <div className={styles.container}>
      <Icon iconId={IconsEnum.SEARCH_OFF} className={styles.icon} color="var(--color-on-bg-primary)" />
      <p className={styles.text}>{txt}</p>
      {skipLink ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <React.Fragment />
      ) : (
        <AnchorLink className={styles.link} href={pageTopUrl}>
          {foundNewGamesLabel}
        </AnchorLink>
      )}
    </div>
  );
};
