import { CardType, ConditionalCard } from '../components/conditionalCard/ConditionalCard';
import { FreeMode, Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CardTypes } from '../../cards/types';
import { SectionTitle } from '../../sectionTitle/SectionTitle';
import { SectionTitleType } from '../../sectionTitle';
import { Wrapper } from '../../wrapper/Wrapper';
import { breakpoint } from '../../../utility/constant';
import { isTruthy } from 'utility/functions';
import styles from './ChartCarousel.module.scss';
import { useHideNavigationCarousel } from '../../../hooks/useHideNavigationCarousel';
import { useRef } from 'react';

export type ChartCarouselProps<T extends CardTypes> = Omit<SectionTitleType, 'nopadding' | 'className'> & {
  listItem: T[];
  typeOfItem: CardType;
  className?: string;
};

export const ChartCarousel = ({
  title,
  subtitle,
  url,
  listItem,
  typeOfItem,
  className,
}: ChartCarouselProps<CardTypes>) => {
  const swiperRef = useRef<any>(null);

  let { isScrollable, swiperSlideRef } = useHideNavigationCarousel({ className: 'disabled' });

  return (
    <Wrapper noPaddingRight noPaddingLeft classNameWrapper={className}>
      {title && <SectionTitle title={title} subtitle={subtitle} url={url} />}
      <div className={styles.container}>
        <Swiper
          onInit={(swiper) => (swiperSlideRef.current = swiper.el)}
          className={styles.carousel}
          spaceBetween={16}
          grabCursor
          slidesPerView={'auto'}
          slidesOffsetBefore={20}
          slidesOffsetAfter={20}
          modules={[Navigation, FreeMode, Mousewheel]}
          updateOnWindowResize
          watchOverflow
          freeMode={{
            enabled: true,
            minimumVelocity: 0.2,
          }}
          mousewheel={{ forceToAxis: true }}
          navigation={isScrollable ? true : false}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          breakpoints={{
            [breakpoint.sm]: {
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
            },
            [breakpoint.lg]: {
              grabCursor: false,
              spaceBetween: 24,
              slidesOffsetBefore: 40,
              slidesOffsetAfter: 40,
              simulateTouch: false,
              allowTouchMove: false,
            },
          }}
        >
          {isTruthy(listItem?.length) &&
            listItem.map((item, index) => {
              return (
                <SwiperSlide className={styles.slide} key={`${item.name}-${index}`}>
                  <ConditionalCard type={typeOfItem} item={item} position={index + 1} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </Wrapper>
  );
};
