import { useMightLike } from '../context/Mightlikes';
import { convertInPotrebbePiacertiType } from '../lib/datoCms/utils';

export const useGetMightLikesByList = (dataPlatformList: string[] | undefined) => {
  const mightLikesList = useMightLike();
  if (dataPlatformList) {
    const mightLikesListMatched = mightLikesList?.filter(({ idDataPlatform }) => {
      return dataPlatformList?.find((id: string) => id === idDataPlatform);
    });
    return convertInPotrebbePiacertiType(mightLikesListMatched);
  }
  return;
};
