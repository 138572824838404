import { QuickSlotProps } from './QuickSlot';
import { SkeletonBox } from 'components/skeletonBox';
import styles from './QuickSlot.module.scss';
import { useLazyLoad } from 'hooks';

export const QuickSlot = (props: QuickSlotProps) => {
  const placeholder = <SkeletonBox className={styles.skeleton} />;

  return useLazyLoad({
    props,
    height: '23.2rem',
    placeholder,
    doLoad: () => import('components/quickSlot/QuickSlot').then((mod) => mod.QuickSlot),
  });
};
