import { CardListEmptyResult } from 'components/cardList/components/cardListEmptyResult';
import React, { Fragment, useMemo } from 'react';
import { isTruthy } from 'utility/functions';
import { Wrapper } from '../wrapper';
import { RenderComponentByModel } from './RenderComponentByModel';

export type VisualComposerProps = {
  type: string;
  components: VisualComposer<any>[] | undefined;
};
export type VisualComposer<T> = {
  id: string;
  link?: string;
  title: string;
  subTitle?: string;
  modelApiKey: string;
  componentData: T;
};

export const VisualComposer = ({ type, components }: VisualComposerProps) => {
  const { items, isEmpty } = useMemo(() => {
    const items = components?.filter((x) => x?.modelApiKey && (x?.componentData || isTruthy(x?.componentData?.length)));
    const isEmpty = (items ?? []).length === 0;
    return {
      items,
      isEmpty,
    };
  }, [components]);

  if (isEmpty && type !== 'lotteries') {
    return (
      <Wrapper>
        <CardListEmptyResult skipLink type={type} />
      </Wrapper>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {items!.map((component: VisualComposer<any>) => {
        return <Fragment key={component.id}>{RenderComponentByModel(component)}</Fragment>;
      })}
    </React.Fragment>
  );
};
