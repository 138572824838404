import { AnchorLink, AnchorLinkType } from 'components/anchorLink';

import { DynamicImage } from 'components/Image';
import { ImageProps } from 'next/image';
import { breakpoint } from 'utility/constant';
import { isNotEmpty } from 'utility/functions';
import styles from './PokerTournamentsAvailableCard.module.scss';
import { useGetLabelByKey } from 'hooks/useLingUI';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type PokerTournamentsAvailableCardProps = {
  id: string;
  title: string;
  imgMob: ImageProps;
  imgDsk: ImageProps;
  detailLink: AnchorLinkType[];
};
export const PokerTournamentsAvailableCard = ({ ...props }) => {
  const { doCheck } = useWindowSize();

  const [isSM] = doCheck([{ comparer: enComparer.less, size: breakpoint.sm }]);

  const labelScopri = useGetLabelByKey('scopri-torneo-disponibile')!;
  return (
    <div className={styles.card}>
      <h2 className={styles.title}>{props.title}</h2>
      <AnchorLink href={props.detailLink[0].href} className={styles.cta}>
        {labelScopri}
      </AnchorLink>
      {isSM
        ? isNotEmpty(props?.imgMob?.src) && (
            <DynamicImage
              src={props?.imgMob?.src}
              alt={props?.imgMob?.alt ?? ''}
              fill
              loading="lazy"
              decoding="async"
              className={styles.image}
            />
          )
        : isNotEmpty(props?.imgDsk?.src) && (
            <DynamicImage
              src={props?.imgDsk?.src}
              alt={props?.imgDsk?.alt ?? ''}
              fill
              loading="lazy"
              decoding="async"
              className={styles.image}
            />
          )}
    </div>
  );
};
