import { CardType, ConditionalCard } from '../components/conditionalCard/ConditionalCard';
import { SectionTitle, SectionTitleType } from '../../sectionTitle';

import { CardTypes } from '../../cards/types';
import { Wrapper } from '../../wrapper';
import { breakpoint } from '../../../utility/constant';
import styles from './HighlightsCarousel.module.scss';
import { useTypedSelector } from 'lib/centralStore';
import useWindowSize, { enComparer } from 'hooks/useWindowSize';

export type HighlightsCarouselProps<T extends CardTypes> = Omit<SectionTitleType, 'nopadding' | 'className'> & {
  typeOfItem: CardType;
  listItem: T[];
};

export const HighlightsCarousel: React.FC<HighlightsCarouselProps<CardTypes>> = ({
  title,
  subtitle,
  url,
  typeOfItem,
  listItem,
}: HighlightsCarouselProps<CardTypes>) => {
  const list = [...listItem];
  const firstElement = list.shift();
  const { doCheck } = useWindowSize();

  const [noPaddingRight, noPaddingLeft] = doCheck([
    { comparer: enComparer.less, size: breakpoint.md },
    { comparer: enComparer.less, size: breakpoint.sm },
  ]);

  return (
    <Wrapper noPaddingLeft={noPaddingLeft} noPaddingRight={noPaddingRight}>
      {title && <SectionTitle url={url} title={title} subtitle={subtitle} noPadding={!noPaddingLeft} />}

      {list && (
        <div className={styles.cardsContainer}>
          <ConditionalCard
            type={typeOfItem}
            item={firstElement}
            containerClass={styles.mainCard}
            jackpotClass={styles.mainJackpot}
            isCover
          />
          <div className={styles.cardListContainer}>
            {list.map((item, index): any | undefined => {
              if (index < 6) {
                return (
                  <ConditionalCard
                    key={item.slug + '_' + index}
                    type={typeOfItem}
                    item={item}
                    containerClass={styles.card}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
    </Wrapper>
  );
};
