import React, { useRef } from 'react';
import { Navigation } from 'swiper/modules';
// import 'swiper/scss';
import { useHideNavigationCarousel } from '../../../hooks/useHideNavigationCarousel';
import { breakpoint } from '../../../utility/constant';
import { CardTypes } from '../../cards/types';
import { SectionTitleType } from '../../sectionTitle';
import { SectionTitle } from '../../sectionTitle/SectionTitle';
import { Wrapper } from '../../wrapper/Wrapper';
import { CardType, ConditionalCard } from '../components/conditionalCard/ConditionalCard';
import styles from './InEvidenceCarousel.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';

export type InEvidenceCarouselProps<T extends CardTypes> = Omit<SectionTitleType, 'nopadding' | 'className'> & {
  typeOfItem: CardType;
  listItem: T[];
};

export const InEvidenceCarousel: React.FC<InEvidenceCarouselProps<CardTypes>> = ({
  title,
  subtitle,
  url,
  typeOfItem,
  listItem,
}: InEvidenceCarouselProps<CardTypes>) => {
  const swiperRef = useRef<any>(null);

  let { isScrollable, swiperSlideRef } = useHideNavigationCarousel({ className: 'disabled' });
  return (
    <Wrapper noPaddingRight noPaddingLeft>
      {title && <SectionTitle title={title} subtitle={subtitle} url={url} />}
      <div className={styles.container}>
        <Swiper
          onInit={(swiper) => (swiperSlideRef.current = swiper.el)}
          className={styles.carousel}
          spaceBetween={24}
          grabCursor={false}
          slidesPerView={'auto'}
          slidesOffsetBefore={20}
          modules={[Navigation]}
          updateOnWindowResize
          watchOverflow
          loop
          speed={700}
          navigation={isScrollable ? true : false}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          breakpoints={{
            [breakpoint.sm]: {
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
            },
            [breakpoint.lg]: {
              grabCursor: false,
              slidesOffsetBefore: 149,
              slidesOffsetAfter: 50,
              simulateTouch: false,
              allowTouchMove: false,
            },
          }}
        >
          {listItem &&
            listItem.map((item, index) => {
              return (
                <SwiperSlide key={item.slug + '_' + index}>
                  <ConditionalCard type={typeOfItem} item={item} jackpotClass={styles.jackpot} isCover />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </Wrapper>
  );
};
