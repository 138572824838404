// BANNER
export const BANNER = 'banner_on_page';

export const BANNER_PROMO = 'promo';
// STRIPE
export const STRIPE = 'stripe_scorrevole';

// SLOT - DataPlatform carousels
export const SLOT_IN_EVIDENCE_CONSOLE_GIOCHI = 'in-evidence_console_giochi_slot_list';
export const SLOT_CLASSIFICA_CONSOLE_GIOCHI = 'classifica_console_giochi_slot_list';
export const SLOT_ZOOM_CONSOLE_GIOCHI = 'zoom_console_giochi_slot_list';
export const SLOT_DEFAULT_CONSOLE_GIOCHI = 'default_console_giochi_slot_list';

// SLOT - Custom carousels
export const SLOT_IN_EVIDENCE_CONSOLE_CUSTOM = 'in-evidence_custom_slot_list';
export const SLOT_CLASSIFICA_CONSOLE_CUSTOM = 'classifica_custom_slot_list';
export const SLOT_ZOOM_CONSOLE_CUSTOM = 'zoom_custom_slot_list';
export const SLOT_DEFAULT_CONSOLE_CUSTOM = 'default_custom_slot_list';

// CASINO - DataPlatform carousels
export const CASINO_IN_EVIDENCE_CONSOLE_GIOCHI = 'in-evidence_console_giochi_casino_list';
export const CASINO_CLASSIFICA_CONSOLE_GIOCHI = 'classifica_console_giochi_casino_list';
export const CASINO_ZOOM_CONSOLE_GIOCHI = 'zoom_console_giochi_casino_list';
export const CASINO_DEFAULT_CONSOLE_GIOCHI = 'default_console_giochi_casino_list';

// CASINO - Custom carousels
export const CASINO_IN_EVIDENCE_CONSOLE_CUSTOM = 'in-evidence_custom_casino_list';
export const CASINO_CLASSIFICA_CONSOLE_CUSTOM = 'classifica_custom_casino_list';
export const CASINO_ZOOM_CONSOLE_CUSTOM = 'zoom_custom_casino_list';
export const CASINO_DEFAULT_CONSOLE_CUSTOM = 'default_custom_casino_list';

// CASINO LIVE
export const CASINO_LIVE_DEFAULT_CONSOLE_GIOCHI = 'default_console_giochi_casino_live_list';
// GIOCHI DI CARTE - DataPlatform carousel
export const CARD_GAMES_IN_EVIDENCE_CONSOLE_GIOCHI = 'in-evidence_console_giochi_card_games_list';
export const CARD_GAMES_CLASSIFICA_CONSOLE_GIOCHI = 'classifica_console_giochi_card_games_list';
export const CARD_GAMES_ZOOM_CONSOLE_GIOCHI = 'zoom_console_giochi_card_games_list';
export const CARD_GAMES_DEFAULT_CONSOLE_GIOCHI = 'default_console_giochi_card_games_list';

// GIOCHI DI CARTE - Custom carousels
export const CARD_GAMES_IN_EVIDENCE_CONSOLE_CUSTOM = 'in-evidence_custom_card_games_list';
export const CARD_GAMES_CLASSIFICA_CONSOLE_CUSTOM = 'classifica_custom_card_games_list';
export const CARD_GAMES_ZOOM_CONSOLE_CUSTOM = 'zoom_custom_card_games_list';
export const CARD_GAMES_DEFAULT_CONSOLE_CUSTOM = 'default_custom_card_games_list';

// QUICK SLOT
export const QUICKSLOT = 'quick_slot';

// Bingo - Custom carousels
export const BINGO_CHART_CONSOLE_GIOCHI = 'chart_console_giochi_bingo_list';

// POKER - Custom carousels
export const POKER_ZOOM_CONSOLE_CUSTOM = 'zoom_custom_poker_list';
export const POKER_DEFAULT_CONSOLE_CUSTOM = 'default_custom_poker_list';

export const POKER_TOURNAMENT_AVAILABLE = 'poker_tournaments_available';
