import { AnchorLink, AnchorLinkType } from '../../anchorLink';

import { DynamicImage } from 'components/Image';
import { ImageProps } from 'next/image';
import { isNotEmpty } from 'utility/functions';
import styles from './SuggestionCard.module.scss';

export type SuggestionCardProps = {
  id?: string;
  image: ImageProps;
  link: AnchorLinkType[];
  label?: string;
  title: string;
  className?: string;
};

export const SuggestionCard: React.FC<SuggestionCardProps> = ({
  image,
  link,
  title,
  label,
  className,
}: SuggestionCardProps) => {
  return (
    <article className={`${styles.container} ${className ? className : ''}`}>
      {isNotEmpty(image.src) && (
        <DynamicImage
          src={image.src}
          alt={image.alt ?? ''}
          title={image.title}
          fill
          loading="lazy"
          decoding="async"
          className={styles.image}
        />
      )}
      {/* TODO la struttura dovrebbe essere {label && <AnchorLink} */}
      <AnchorLink href={link[0].href} className={styles.link}>
        {label && label}
      </AnchorLink>
      <span className={styles.label}>{title}</span>
    </article>
  );
};
