import {
  PokerTournamentsAvailableCard,
  PokerTournamentsAvailableCardType,
} from 'components/cards/pokerTournamentsAvailableCard';
import { Wrapper } from '../wrapper';
import styles from './PokerTournamentsAvailable.module.scss';
export type PokerTournamentsAvailableProps = {
  pokerTournamentsAvailableList: Array<PokerTournamentsAvailableCardType>;
};

export const PokerTournamentsAvailable = ({ pokerTournamentsAvailableList }: PokerTournamentsAvailableProps) => {
  return (
    <Wrapper bgColorTransparent>
      <div className={styles.container}>
        {pokerTournamentsAvailableList.map((pokerTournament) => {
          return <PokerTournamentsAvailableCard key={pokerTournament.id} {...pokerTournament} />;
        })}
      </div>
    </Wrapper>
  );
};
