import { PokerTournamentsAvailable } from 'components/pokerTournamentsAvailable';
import React from 'react';
import { BannerLandscape } from '../banner/bannerLandscape';
import { ChartCarousel } from '../carousels/chartCarousel';
import { DefaultCarousel } from '../carousels/defaultCarousel';
import { HighlightsCarousel } from '../carousels/highlightsCarousel';
import { InEvidenceCarousel } from '../carousels/inEvidenceCarousel';
import { Top10Carousel } from '../carousels/top10Carousel';
import { QuickSlot } from '../quickSlot';
import { StripeScorrevole } from '../stripeScorrevole';
import { VisualComposer } from './VisualComposer';
import {
  BANNER,
  BANNER_PROMO,
  BINGO_CHART_CONSOLE_GIOCHI,
  CARD_GAMES_CLASSIFICA_CONSOLE_CUSTOM,
  CARD_GAMES_CLASSIFICA_CONSOLE_GIOCHI,
  CARD_GAMES_DEFAULT_CONSOLE_CUSTOM,
  CARD_GAMES_DEFAULT_CONSOLE_GIOCHI,
  CARD_GAMES_IN_EVIDENCE_CONSOLE_CUSTOM,
  CARD_GAMES_IN_EVIDENCE_CONSOLE_GIOCHI,
  CARD_GAMES_ZOOM_CONSOLE_CUSTOM,
  CARD_GAMES_ZOOM_CONSOLE_GIOCHI,
  CASINO_CLASSIFICA_CONSOLE_CUSTOM,
  CASINO_CLASSIFICA_CONSOLE_GIOCHI,
  CASINO_DEFAULT_CONSOLE_CUSTOM,
  CASINO_DEFAULT_CONSOLE_GIOCHI,
  CASINO_IN_EVIDENCE_CONSOLE_CUSTOM,
  CASINO_IN_EVIDENCE_CONSOLE_GIOCHI,
  CASINO_LIVE_DEFAULT_CONSOLE_GIOCHI,
  CASINO_ZOOM_CONSOLE_CUSTOM,
  CASINO_ZOOM_CONSOLE_GIOCHI,
  POKER_DEFAULT_CONSOLE_CUSTOM,
  POKER_TOURNAMENT_AVAILABLE,
  POKER_ZOOM_CONSOLE_CUSTOM,
  QUICKSLOT,
  SLOT_CLASSIFICA_CONSOLE_CUSTOM,
  SLOT_CLASSIFICA_CONSOLE_GIOCHI,
  SLOT_DEFAULT_CONSOLE_CUSTOM,
  SLOT_DEFAULT_CONSOLE_GIOCHI,
  SLOT_IN_EVIDENCE_CONSOLE_CUSTOM,
  SLOT_IN_EVIDENCE_CONSOLE_GIOCHI,
  SLOT_ZOOM_CONSOLE_CUSTOM,
  SLOT_ZOOM_CONSOLE_GIOCHI,
  STRIPE,
} from './constants';

export const RenderComponentByModel = ({ link, title, subTitle, modelApiKey, componentData }: VisualComposer<any>) => {
  try {
    switch (modelApiKey) {
      // BANNER
      case BANNER: {
        return <BannerLandscape data={componentData} />;
      }
      // SLOT
      case SLOT_CLASSIFICA_CONSOLE_GIOCHI: {
        return <Top10Carousel title={title} subtitle={subTitle} typeOfItem="top10" listItem={componentData} />;
      }
      case SLOT_CLASSIFICA_CONSOLE_CUSTOM: {
        return <Top10Carousel title={title} subtitle={subTitle} typeOfItem="top10" listItem={componentData} />;
      }
      case SLOT_IN_EVIDENCE_CONSOLE_GIOCHI: {
        return (
          <HighlightsCarousel title={title} typeOfItem="slot" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      case SLOT_IN_EVIDENCE_CONSOLE_CUSTOM: {
        return (
          <HighlightsCarousel title={title} typeOfItem="slot" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      case SLOT_ZOOM_CONSOLE_GIOCHI: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel title={title} typeOfItem="slot" listItem={componentData} url={link} subtitle={subTitle} />
        ) : (
          <DefaultCarousel title={title} subtitle={subTitle} typeOfItem="slot" listItem={componentData} url={link} />
        );
      }
      case SLOT_ZOOM_CONSOLE_CUSTOM: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel title={title} typeOfItem="slot" listItem={componentData} url={link} subtitle={subTitle} />
        ) : (
          <DefaultCarousel subtitle={subTitle} title={title} typeOfItem="slot" listItem={componentData} url={link} />
        );
      }
      case SLOT_DEFAULT_CONSOLE_GIOCHI: {
        return (
          <DefaultCarousel title={title} typeOfItem="slot" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      case SLOT_DEFAULT_CONSOLE_CUSTOM: {
        return (
          <DefaultCarousel title={title} typeOfItem="slot" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      // CASINO
      case CASINO_CLASSIFICA_CONSOLE_GIOCHI: {
        return <Top10Carousel title={title} subtitle={subTitle} typeOfItem="top10" listItem={componentData} />;
      }
      case CASINO_CLASSIFICA_CONSOLE_CUSTOM: {
        return <Top10Carousel title={title} subtitle={subTitle} typeOfItem="top10" listItem={componentData} />;
      }
      case CASINO_IN_EVIDENCE_CONSOLE_GIOCHI: {
        return (
          <HighlightsCarousel
            title={title}
            typeOfItem="casino"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CASINO_IN_EVIDENCE_CONSOLE_CUSTOM: {
        return (
          <HighlightsCarousel
            title={title}
            typeOfItem="casino"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CASINO_ZOOM_CONSOLE_GIOCHI: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel
            title={title}
            typeOfItem="casino"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        ) : (
          <DefaultCarousel title={title} typeOfItem="casino" listItem={componentData} subtitle={subTitle} url={link} />
        );
      }
      case CASINO_ZOOM_CONSOLE_CUSTOM: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel
            title={title}
            typeOfItem="casino"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        ) : (
          <DefaultCarousel title={title} typeOfItem="casino" listItem={componentData} subtitle={subTitle} url={link} />
        );
      }
      case CASINO_DEFAULT_CONSOLE_GIOCHI: {
        return (
          <DefaultCarousel title={title} typeOfItem="casino" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      case CASINO_DEFAULT_CONSOLE_CUSTOM: {
        return (
          <DefaultCarousel title={title} typeOfItem="casino" listItem={componentData} subtitle={subTitle} url={link} />
        );
      }
      // CASINO LIVE
      case CASINO_LIVE_DEFAULT_CONSOLE_GIOCHI: {
        return (
          <DefaultCarousel
            title={title}
            typeOfItem={'casinolive'}
            listItem={componentData}
            subtitle={subTitle}
            url={link}
          />
        );
      }
      // GIOCHI DI CARTE
      case CARD_GAMES_CLASSIFICA_CONSOLE_GIOCHI: {
        return <Top10Carousel title={title} subtitle={subTitle} typeOfItem="top10" listItem={componentData} />;
      }
      case CARD_GAMES_CLASSIFICA_CONSOLE_CUSTOM: {
        return <Top10Carousel title={title} subtitle={subTitle} typeOfItem="top10" listItem={componentData} />;
      }
      case CARD_GAMES_IN_EVIDENCE_CONSOLE_GIOCHI: {
        return (
          <HighlightsCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CARD_GAMES_IN_EVIDENCE_CONSOLE_CUSTOM: {
        return (
          <HighlightsCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CARD_GAMES_ZOOM_CONSOLE_GIOCHI: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        ) : (
          <DefaultCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CARD_GAMES_ZOOM_CONSOLE_CUSTOM: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        ) : (
          <DefaultCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CARD_GAMES_DEFAULT_CONSOLE_GIOCHI: {
        return (
          <DefaultCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      case CARD_GAMES_DEFAULT_CONSOLE_CUSTOM: {
        return (
          <DefaultCarousel
            title={title}
            typeOfItem="giochidicarte"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        );
      }
      // QUICK SLOT
      case QUICKSLOT: {
        return <QuickSlot title={title} subtitle={subTitle!} />;
      }
      // STRIPE
      case STRIPE: {
        return <StripeScorrevole {...componentData} />;
      }
      // BINGO
      case BINGO_CHART_CONSOLE_GIOCHI: {
        return <ChartCarousel title={title} subtitle={subTitle} typeOfItem="chart" listItem={componentData} />;
      }
      // BANNER PROMO
      case BANNER_PROMO: {
        return <BannerLandscape data={componentData[0]} />;
      }
      // POKER
      case POKER_DEFAULT_CONSOLE_CUSTOM: {
        return (
          <DefaultCarousel title={title} typeOfItem="poker" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      case POKER_ZOOM_CONSOLE_CUSTOM: {
        return componentData.length > 6 ? (
          <InEvidenceCarousel
            title={title}
            typeOfItem="poker"
            listItem={componentData}
            url={link}
            subtitle={subTitle}
          />
        ) : (
          <DefaultCarousel title={title} typeOfItem="poker" listItem={componentData} url={link} subtitle={subTitle} />
        );
      }
      case POKER_TOURNAMENT_AVAILABLE: {
        return <PokerTournamentsAvailable pokerTournamentsAvailableList={componentData} />;
      }
    }
  } catch (error) {
    console.error('Error for modelName', modelApiKey);
    console.error(error);
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment />;
};
